import React from 'react';
import { Link } from 'gatsby';
import PlayStore from '../../assets/png/google-play.png';
import AppStore from '../../assets/png/app-store.png';
import BannerRight from '../../assets/png/banner-right.png';
import './style.scss';




const AdsBanner = (props) => {
  return (
    <div className="banner-container">
      <div className="banner-inner desktop">
        <div className="banner-left-container">
          <div>
            <p className="fnt-evolventa bold banner-text">
              {props.language === 'eng' ? 'Try our mobile app now' : 'Prueba nuestra aplicación móvil ahora'}
            </p>
          </div>
          <div className="dsp-flx flx-center">
            <div className="redirect-link">
              <Link to="" target="__blank">
                <img src={PlayStore} alt="" className="playstore-icon" />
              </Link>
            </div>
            <div className="redirect-link pl-3">
              <Link to="" target="__blank">
                <img src={AppStore} alt="" className="appstore-icon" />
              </Link>
            </div>
          </div>
        </div>
        <div className="banner-bg-container">
          <img src={BannerRight} alt="" className="banner-bg" />
        </div>
      </div>
      <div className="banner-inner mobile">
        <h2 className="fnt-evolventa bold banner-text">
          {props.language === 'eng' ? 'Try our mobile app now' : 'Prueba nuestra aplicación móvil ahora'}
        </h2>
        <div className="dsp-flx flx-center">
          <div className="redirect-link">
            <Link to="" target="__blank">
              <img src={PlayStore} alt="" className="playstore-icon" />
            </Link>
          </div>
          <div className="redirect-link pl-3">
            <Link to="" target="__blank">
              <img src={AppStore} alt="" className="appstore-icon" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdsBanner;
