import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { FiBarChart2 } from 'react-icons/fi';
import { Overlay } from 'react-bootstrap';
import { TYPE_UNLOCK_INTERRUPT_MOBILE_PLAYER, TYPE_LOCK_INTERRUPT_MOBILE_PLAYER } from '../../store/reducer';
import ProgressBar from '../progressBar';
import QuizOverlay from './quizOverlay';

const BookCard = (props) => {
  const { book, quizData } = props;
  const [showOverlay, setOverlay] = useState(false);
  const target = useRef(null);
  const { language } = useSelector((state) => state);
  const dispatch = useDispatch();

  let totalProgress = 0;
  for (let i = 0; i < quizData.length; i++) {
    totalProgress = totalProgress + Number(quizData[i].score);
  }
  const percent = quizData.length ? ((totalProgress / (quizData.length * 100)) * 100).toFixed(2) : 0;

  useEffect(() => {
    if (showOverlay) {
      dispatch({ type: TYPE_LOCK_INTERRUPT_MOBILE_PLAYER });
    } else {
      dispatch({ type: TYPE_UNLOCK_INTERRUPT_MOBILE_PLAYER });
    }
  }, [showOverlay]);

  // console.log(language)

  return (
    <div className={`book-card p-4 ${showOverlay ? 'select' : ''}`}>
      <Link to={language === 'eng' ? `/${book.slug}` : `/es/${book.slug}`}>
        <div className="border-rd-16">
          <img src={book.cover} alt="" className="book-card-img" />
        </div>
      </Link>
      <div className="mt-3">
        <Link to={language === 'eng' ? `/${book.slug}` : `/es/${book.slug}`}>
          <ProgressBar progress={Number(percent)} />
          <div className="book-card-title-container" ref={target}>
            <h2 className="mb-0 fnt-evolventa fnt-r-18 bold fnt-grey">{book.title}</h2>
          </div>
        </Link>
        <div className="quiz-min-height pt-2 z-index-100">
          <div
            className="quiz-progress-container dsp-flx flx-row align-items-center"
            onClick={() => {
              setOverlay(true);
            }}
          >
            <p className="highlight-onhover mb-0 fnt-primary fnt-poppins mr-2">
              {language === 'eng' ? 'Quiz progress' : 'Progreso de la prueba'}
            </p>
            <FiBarChart2 color="#FC650A" size={16} />
          </div>
        </div>
      </div>
      <Overlay
        target={target.current}
        show={showOverlay}
        rootClose={true}
        onHide={() => {
          setOverlay(false);
        }}
        placement="bottom-start"
      >
        {({ ...props }) => (
          <div
            {...props}
            className="quiz-overlay-container"
            style={{
              ...props.style,
              backgroundColor: '#FFF',
              padding: '2px 10px',
              color: 'black',
              maxHeight: '16rem',
              width: '40rem',
              borderRadius: 3,
              overflow: 'auto',
            }}
          >
            <QuizOverlay quizData={quizData} lang={language} />
          </div>
        )}
      </Overlay>
    </div>
  );
};

export default BookCard;
